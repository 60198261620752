exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".clientManagement_dspIndicator__2vheC{\n    color:white;\n    width: 100%;\n    height: 3rem;\n    text-align: center;\n    vertical-align: middle;\n    padding-top: 12px;\n}\n\n.clientManagement_red__C2M-h{\n    background-color: red;\n}\n\n.clientManagement_orange__1Qkrl{\n    background-color: orange;\n}\n\n.clientManagement_green__2Xbfx{\n    background-color: green;\n}", ""]);

// Exports
exports.locals = {
	"dspIndicator": "clientManagement_dspIndicator__2vheC",
	"red": "clientManagement_red__C2M-h",
	"orange": "clientManagement_orange__1Qkrl",
	"green": "clientManagement_green__2Xbfx"
};